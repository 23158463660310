import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, reactive, watch } from 'vue';
import Overtime from '@/components/Query/Overtime';
import Compensation from '@/components/Query/Compensation';
import Regular from '@/components/Query/Regular';
import Writeoff from '@/components/Query/Writeoff';
export default {
  __name: 'QueryView',
  setup(__props) {
    const active = ref('');
    const isShowCalendar = ref(false);
    const isShowValuePicker = ref(false);
    const index = ref(0);
    const props = reactive({
      date1: '',
      date2: '',
      value: '',
      namedvalue: ''
    });
    const columns = ref([]);
    const minDate = new Date(2010, 0, 1);
    const maxDate = new Date(2030, 11, 31);
    function formatDate(date) {
      const year = date.getFullYear();
      let month = date.getMonth();
      let day = date.getDate();
      if (day < 10) {
        day = `0${day}`;
      }
      month = month + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      return `${year}年${month}月${day}日`;
    }
    function showCalendar(idx) {
      isShowCalendar.value = true;
      index.value = idx;
    }
    function showValuePicker(data) {
      columns.value = data;
      isShowValuePicker.value = true;
    }
    function onConfirmCalendar(value) {
      isShowCalendar.value = false;
      props[`date${index.value}`] = formatDate(value);
    }
    function onConfirmValuePicker({
      selectedOptions,
      selectedValues
    }) {
      isShowValuePicker.value = false;
      props.namedvalue = selectedOptions[0].text;
      props.value = selectedValues[0];
    }
    function resetProps() {
      props['date1'] = new Date().getFullYear() + '年' + (new Date().getMonth() + 1) + '月' + 1 + '日';
      props['date2'] = new Date().getFullYear() + '年' + (new Date().getMonth() + 1) + '月' + new Date().getDate() + '日';
      props['value'] = '';
      props['namedvalue'] = '全选';
    }
    watch(active, () => {
      resetProps();
    });
    return (_ctx, _cache) => {
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      const _component_van_calendar = _resolveComponent("van-calendar");
      const _component_van_picker = _resolveComponent("van-picker");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_tabs, {
        active: active.value,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => active.value = $event),
        swipeable: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tab, {
          title: "加班",
          name: "overtime"
        }, {
          default: _withCtx(() => [_createVNode(_unref(Overtime), {
            date1: props.date1,
            date2: props.date2,
            value: props.value,
            namedvalue: props.namedvalue,
            onShowCalendar: showCalendar,
            onShowValuePicker: showValuePicker,
            onResetProps: resetProps
          }, null, 8, ["date1", "date2", "value", "namedvalue"])]),
          _: 1
        }), _createVNode(_component_van_tab, {
          title: "补休",
          name: "compensation"
        }, {
          default: _withCtx(() => [_createVNode(_unref(Compensation), {
            date1: props.date1,
            date2: props.date2,
            value: props.value,
            namedvalue: props.namedvalue,
            onShowCalendar: showCalendar,
            onShowValuePicker: showValuePicker,
            onResetProps: resetProps
          }, null, 8, ["date1", "date2", "value", "namedvalue"])]),
          _: 1
        }), _createVNode(_component_van_tab, {
          title: "办公班",
          name: "regular"
        }, {
          default: _withCtx(() => [_createVNode(_unref(Regular), {
            date1: props.date1,
            date2: props.date2,
            value: props.value,
            namedvalue: props.namedvalue,
            onShowCalendar: showCalendar,
            onShowValuePicker: showValuePicker,
            onResetProps: resetProps
          }, null, 8, ["date1", "date2", "value", "namedvalue"])]),
          _: 1
        }), _createVNode(_component_van_tab, {
          title: "核销",
          name: "writeoff"
        }, {
          default: _withCtx(() => [_createVNode(_unref(Writeoff), {
            date1: props.date1,
            date2: props.date2,
            value: props.value,
            namedvalue: props.namedvalue,
            onShowCalendar: showCalendar,
            onShowValuePicker: showValuePicker,
            onResetProps: resetProps
          }, null, 8, ["date1", "date2", "value", "namedvalue"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["active"]), _createVNode(_component_van_calendar, {
        show: isShowCalendar.value,
        "onUpdate:show": _cache[1] || (_cache[1] = $event => isShowCalendar.value = $event),
        onConfirm: onConfirmCalendar,
        "min-date": _unref(minDate),
        "max-date": _unref(maxDate)
      }, null, 8, ["show", "min-date", "max-date"]), _createVNode(_component_van_popup, {
        show: isShowValuePicker.value,
        "onUpdate:show": _cache[3] || (_cache[3] = $event => isShowValuePicker.value = $event),
        round: "",
        position: "bottom"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_picker, {
          columns: columns.value,
          onCancel: _cache[2] || (_cache[2] = $event => isShowValuePicker.value = false),
          onConfirm: onConfirmValuePicker
        }, null, 8, ["columns"])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};